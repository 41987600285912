/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Alignment = "Centre" | "Hidden" | "Left" | "Right" | "%future added value";
export type LeftSide_menuData = {
    readonly currentUser: {
        readonly menuItems: {
            readonly admin: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly claim: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly config: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly dashboard: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly outstandingCommunication: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly reporting: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly scoreboard: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly video: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly bookingService: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
            readonly specialist: {
                readonly alignment: Alignment;
                readonly icon: string | null;
                readonly id: string;
                readonly isDisplay: boolean;
                readonly isExternal: boolean;
                readonly label: string | null;
                readonly url: string | null;
            };
        };
    };
    readonly " $refType": "LeftSide_menuData";
};
export type LeftSide_menuData$data = LeftSide_menuData;
export type LeftSide_menuData$key = {
    readonly " $data"?: LeftSide_menuData$data;
    readonly " $fragmentRefs": FragmentRefs<"LeftSide_menuData">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "alignment",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "icon",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDisplay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isExternal",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeftSide_menuData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthenticatedUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MenuView",
          "kind": "LinkedField",
          "name": "menuItems",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "admin",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "claim",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "config",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "dashboard",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "outstandingCommunication",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "reporting",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "scoreboard",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "video",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "bookingService",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "specialist",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '999682528c8e2fe05984780a43f4985c';
export default node;
