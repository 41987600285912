import React from 'react'

import { RelayEnvironmentProvider } from 'react-relay'
import createEnvironment from 'providers/RelayProvider/createEnvironment'

import { ThemeProvider, CssBaseline } from '@material-ui/core'
import createTheme from './providers/ThemeProvider/theme'

import { SnackbarProvider } from 'notistack'

import { BrowserRouter as Router } from 'react-router-dom'
import RouteProvider from 'providers/RouteProvider'
import { ErrorBoundary } from 'react-error-boundary'
import RouteErrorBoundary from 'components/route/RouteErrorBoundary'
import RouteLoading, { SiteLoading } from 'components/route/RouteLoading'
import routes from 'routes'
import Navbar from 'components/Navbar'
import getTheme, { ThemeName } from 'providers/ThemeProvider/getTheme'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Helmet } from 'react-helmet'
import { COMPANY_RESOURCE } from 'configs'

type AppContextType = {
  resetEnvironment: () => void
  changeTheme: (name: ThemeName) => void
}
const AppContext = React.createContext<AppContextType>({
  resetEnvironment: () => console.log('App is not ready'),
  changeTheme: () => console.log('App is not ready'),
})
export const useAppContext = () => {
  const value = React.useContext(AppContext)
  return value
}

const App = () => {
  const [environment, setEnvironment] = React.useState<any>(createEnvironment())
  const [theme, setTheme] = React.useState(createTheme())

  const resetEnvironment = () => {
    const newEnv = createEnvironment()
    setEnvironment(newEnv)
  }

  const changeTheme = async (name: ThemeName) => {
    const theme = await getTheme(name)
    setTheme(theme)
  }

  let appCustom
  const { APPLICATION_CUSTOM } = localStorage
  if (APPLICATION_CUSTOM) {
    appCustom = JSON.parse(APPLICATION_CUSTOM)
  }

  return (
    <Router>
      {APPLICATION_CUSTOM ? (
        <Helmet>
          <title>{appCustom?.alias}</title>
          <meta name="description" content="Helmet application" />
          <link
            rel="icon"
            id="favicon"
            type="image/x-icon"
            href={COMPANY_RESOURCE + '/' + appCustom?.favicon}
          />
        </Helmet>
      ) : (
        <></>
      )}
      <AppContext.Provider value={{ resetEnvironment, changeTheme }}>
        <RelayEnvironmentProvider environment={environment}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={1500}
                maxSnack={1}
              >
                <ErrorBoundary
                  FallbackComponent={props => {
                    return (
                      <RouteErrorBoundary
                        {...props}
                        errorUrl={
                          window.location.href.indexOf('.net') !== -1
                            ? window.location.href.substring(
                                window.location.href.indexOf('.net') + 4
                              )
                            : undefined
                        }
                      />
                    )
                  }}
                >
                  <React.Suspense fallback={<SiteLoading />}>
                    <Navbar />
                    <React.Suspense fallback={<RouteLoading />}>
                      <RouteProvider routes={routes} />
                    </React.Suspense>
                  </React.Suspense>
                </ErrorBoundary>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </RelayEnvironmentProvider>
      </AppContext.Provider>
    </Router>
  )
}
export default App
