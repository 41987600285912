import { Environment, RecordSource, Store, Observable } from 'relay-runtime'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  retryMiddleware,
  uploadMiddleware,
} from 'react-relay-network-modern'
import { recoverAppMeta, commitAppMeta } from './commitAppMeta'
import {
  API_ENDPOINT,
  WS_API_ENDPOINT,
  WS_SUBSCRIPTION_ENDPOINT,
} from 'configs'

const subscriptionClient = new SubscriptionClient(WS_SUBSCRIPTION_ENDPOINT, {
  reconnect: true,
})

const network = new RelayNetworkLayer(
  [
    urlMiddleware({
      url: ctx => {
        if (ctx.id.indexOf('videoService') !== -1) {
          return Promise.resolve(`${WS_API_ENDPOINT}/graphql`)
        } else {
          return Promise.resolve(`${API_ENDPOINT}/graphql`)
        }
      },
    }),
    retryMiddleware({
      fetchTimeout: 120000,
      retryDelays: attempt => Math.pow(2, attempt + 4) * 100,
      beforeRetry: ({ forceRetry, abort, delay, attempt }) => {
        if (attempt > 2) abort()
        // @ts-ignore
        window.forceRelayRetry = forceRetry
        console.log(
          `call "forceRelayRetry()" for immediately retry! Or wait ${delay} ms.`
        )
      },
      // TODO: take a look on error status code
      statusCodes: [500, 503, 504],
      allowMutations: true,
    }),
    authMiddleware({
      token: () => localStorage.getItem('ACCESS_TOKEN') ?? '',
      tokenRefreshPromise: req => {
        console.log('[client.js] resolve token refresh', req)
        const token = localStorage.getItem('ACCESS_TOKEN')
        if (token && localStorage.REFRESH_TOKEN) {
          return fetch(`${API_ENDPOINT}/graphql`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${localStorage.REFRESH_TOKEN}`,
            },
            body: JSON.stringify({
              query: `
                mutation {
                  userRenewToken {
                    refreshToken
                    accessToken
                  }
                }
              `,
            }),
          })
            .then(res => res.json())
            .then(res => {
              if (!res.data?.userRenewToken) {
                throw new Error('no token returned')
              }

              const { accessToken, refreshToken } = res.data.userRenewToken
              // TODO find a way to clean up this
              // @ts-ignore
              const environment = window.env
              commitAppMeta(environment, { accessToken, refreshToken })
              return res.data.userRenewToken.accessToken
            })
            .catch(error => {
              console.log(`[client.js] ERROR can not refresh token ${error}`)
              localStorage.clear()
            })
        }
        return 'no token'
      },
    }),
    uploadMiddleware(),
  ],
  {
    noThrow: true,
    // @ts-ignore
    subscribeFn: (request, variables) => {
      const subscribeObservable = subscriptionClient.request({
        query: request.text || undefined,
        operationName: request.name,
        variables,
      })

      // @ts-ignore
      return Observable.from(subscribeObservable)
    },
  }
)

export default () => {
  const environment = new Environment({
    network,
    store: new Store(new RecordSource()),
  })
  // TODO find a way to clean up this
  // @ts-ignore
  window.env = environment

  // init local state data
  recoverAppMeta(environment)

  return environment
}
