/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RightSide_communication_data = {
    readonly communications: {
        readonly totalCount: number | null;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly claimId: string | null;
                readonly claimRef: string;
                readonly message: string | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "RightSide_communication_data";
};
export type RightSide_communication_data$data = RightSide_communication_data;
export type RightSide_communication_data$key = {
    readonly " $data"?: RightSide_communication_data$data;
    readonly " $fragmentRefs": FragmentRefs<"RightSide_communication_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RightSide_communication_data",
  "selections": [
    {
      "alias": "communications",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "acknowledged": false,
            "boxes": "Inbox"
          }
        }
      ],
      "concreteType": "ClaimCommunicationConnection",
      "kind": "LinkedField",
      "name": "claimCommunications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClaimCommunicationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClaimCommunication",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "claimId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "claimRef",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "claimCommunications(first:2,where:{\"acknowledged\":false,\"boxes\":\"Inbox\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '38dc6509193183977e5f3992013b43b1';
export default node;
