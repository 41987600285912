import React from 'react'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useMutation } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import mutationHandleSnackbar from 'utils/mutationHandleSnackbar'
import { useSnackbar } from 'notistack'

type AnnouncementDialogProps = {
  announcements: any
  handleClose: any
}
const AnnouncementDialog: React.FC<AnnouncementDialogProps> = ({
  announcements,
  handleClose,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const getStepContent = (activeStep: any) => {
    if (
      announcements &&
      announcements[activeStep] &&
      announcements[activeStep].node
    )
      return announcements[activeStep]?.node?.content
  }
  const [commit] = useMutation<any>(
    graphql`
      mutation AnnouncementDialogMutation($input: AnnouncementLogCreateInput!) {
        announcementLogCreate(input: $input) {
          messages
          success
        }
      }
    `
  )
  const handleClick = () => {
    setLoading(true)
    commit({
      variables: {
        input: {
          announcementId: announcements[activeStep].node?.id,
        },
      },
      onCompleted: (res, errors) => {
        mutationHandleSnackbar(
          res?.announcementLogCreate as any,
          errors,
          enqueueSnackbar,
          () => {
            activeStep === announcements.length - 1
              ? handleClose()
              : handleNext()
            setLoading(false)
          }
        )
      },
    })
    closeSnackbar()
  }

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        style={{ paddingLeft: 0, paddingRight: 0, overflow: 'auto' }}
      >
        {announcements.map((item: any, index: any) => (
          <Step key={index}>
            <StepLabel>{item.node.displayType.toString()}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div style={{ fontWeight: 600, fontSize: 'larger' }}>
          {announcements[activeStep]?.node?.title.toString()}
        </div>
        <p dangerouslySetInnerHTML={{ __html: getStepContent(activeStep) }} />
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={loading}
          >
            Acknowledge
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AnnouncementDialog
