import type { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack'

type Snackbar = (
  message: SnackbarMessage,
  options?: OptionsObject | undefined
) => SnackbarKey
type Result = {
  success: boolean
  messages: any[]
}
export default (
  result: Result,
  errors: any[] | null,
  snackbar: Snackbar,
  onSuccess?: () => void,
  onError?: () => void,
  onNoError?: () => void,
  onFailed?: () => void,
  autoHideDuration?: [success: number | undefined, error: number | undefined]
) => {
  if (errors) {
    onError && onError()
    errors?.forEach(error => {
      snackbar(error.message, {
        variant: 'error',
        autoHideDuration: autoHideDuration?.[1],
      })
    })
  } else {
    onNoError && onNoError()
    const { success, messages } = result
    if (!success) {
      snackbar(messages[0], {
        variant: 'error',
        autoHideDuration: autoHideDuration?.[1],
      })
      onFailed && onFailed()
    } else {
      snackbar(messages[0], {
        variant: 'success',
        autoHideDuration: autoHideDuration?.[0],
      })
      onSuccess && onSuccess()
    }
  }
}
