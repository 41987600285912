/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RightSide_menuIcon_data = {
    readonly " $fragmentRefs": FragmentRefs<"SystemMenuPropsData">;
    readonly " $refType": "RightSide_menuIcon_data";
};
export type RightSide_menuIcon_data$data = RightSide_menuIcon_data;
export type RightSide_menuIcon_data$key = {
    readonly " $data"?: RightSide_menuIcon_data$data;
    readonly " $fragmentRefs": FragmentRefs<"RightSide_menuIcon_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RightSide_menuIcon_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SystemMenuPropsData"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '9478e60f9cf0f5c5b94c7b192fe51403';
export default node;
