import React from 'react'
import { AppBar, Toolbar, makeStyles, Container } from '@material-ui/core'
import { useQueryLoader, usePreloadedQuery } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { NavbarQuery } from './__generated__/NavbarQuery.graphql'
import LeftSide from './LeftSide'
import RightSide from './RightSide'
import { useHistory, useLocation } from 'react-router-dom'
import Announcement from '../Announcement/Announcement'
import { GraphQLTaggedNode } from 'relay-runtime'
import useQueryString from 'hooks/useQueryString'

type NavbarProps = {
  showAnnouncement?: any
  queryReference: any
  disposeQuery: any
  query: GraphQLTaggedNode
}
const Navbar: React.FC<NavbarProps> = ({
  showAnnouncement,
  queryReference,
  disposeQuery,
  query,
}) => {
  const classes = useStyles()
  const data = usePreloadedQuery<NavbarQuery>(query, queryReference)

  const isFetched = showAnnouncement?.firstRender
    ? showAnnouncement?.firstRender
    : localStorage.getItem('firstRender')
    ? localStorage.getItem('firstRender')
    : false

  return (
    <>
      <AppBar position="sticky">
        <Container maxWidth="xl" className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.leftSide}>
              <LeftSide data={data.me} menuData={data} />
            </div>
            <div className={classes.rightSide}>
              <RightSide
                data={data.me}
                menuData={data}
                announcementData={data}
                communicationData={data}
                disposeNavbarQuery={disposeQuery}
              />
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      {isFetched && <Announcement data={data} isFetched={isFetched} />}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    minHeight: 48,
    height: 48,
    width: '120%',
    [theme.breakpoints.up('md')]: { width: '100%' },
    [theme.breakpoints.up('lg')]: { width: '80%' },

    justifyContent: 'space-between',
  },
  leftSide: {
    marginRight: 'auto',
    height: '100%',
    display: 'flex',
    paddingLeft: 'auto',
    paddingRight: 'auto',
  },
  rightSide: {
    marginLeft: 'auto',
    height: '100%',
    display: 'flex',
    paddingLeft: 'auto',
    paddingRight: 'auto',
  },
}))

const NavbarWrapper = () => {
  const { pathname } = useLocation()
  const { location } = useHistory()
  const showAnnouncement = location.state
  localStorage.setItem('firstRender', 'true')
  const storedToken = React.useMemo(() => {
    return localStorage.getItem('ACCESS_TOKEN')
    // eslint-disable-next-line
  }, [pathname])
  const { token: urlToken } = useQueryString()

  const query = graphql`
    query NavbarQuery {
      ...RightSide_announcementData
      ...RightSide_communication_data
      ...Announcement_data
      ...LeftSide_menuData
      ...RightSide_menuIcon_data
      me {
        ...LeftSide_data
        ...RightSide_data
      }
    }
  `
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query)
  const classes = useStyles()

  React.useEffect(() => {
    if (urlToken) return
    if (storedToken) {
      loadQuery({}, { fetchPolicy: 'network-only' })
    }
  }, [storedToken, urlToken, loadQuery])

  const noLoginToken = localStorage.getItem('NO_LOGIN_TOKEN')
  if (!storedToken || noLoginToken || pathname === '/survey') return null

  return (
    <React.Suspense
      fallback={
        <AppBar position="sticky" style={{ opacity: 0.75 }}>
          <Container maxWidth="xl" className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <div className={classes.leftSide}></div>
              <div className={classes.rightSide}></div>
            </Toolbar>
          </Container>
        </AppBar>
      }
    >
      {queryReference !== null && (
        <Navbar
          showAnnouncement={showAnnouncement ? showAnnouncement : null}
          query={query}
          queryReference={queryReference}
          disposeQuery={disposeQuery}
        />
      )}
    </React.Suspense>
  )
}
export default NavbarWrapper
