/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RightSide_announcementData = {
    readonly currentUser: {
        readonly menuItems: {
            readonly announcementsNotification: {
                readonly icon: string | null;
                readonly isDisplay: boolean;
            };
        };
    };
    readonly " $fragmentRefs": FragmentRefs<"AnnouncementsBell_data">;
    readonly " $refType": "RightSide_announcementData";
};
export type RightSide_announcementData$data = RightSide_announcementData;
export type RightSide_announcementData$key = {
    readonly " $data"?: RightSide_announcementData$data;
    readonly " $fragmentRefs": FragmentRefs<"RightSide_announcementData">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RightSide_announcementData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthenticatedUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MenuView",
          "kind": "LinkedField",
          "name": "menuItems",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuItem",
              "kind": "LinkedField",
              "name": "announcementsNotification",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "icon",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDisplay",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AnnouncementsBell_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '37d36ed115a0944a43b26e5d22ff0ed3';
export default node;
