import React from 'react'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

type AnnouncementsBellDialogProps = {
  announcements: any
  activeStep: any
  setActiveStep: any
  handleSubDialogClose: any
}
const AnnouncementsBellDialog: React.FC<AnnouncementsBellDialogProps> = ({
  announcements,
  activeStep,
  setActiveStep,
  handleSubDialogClose,
}) => {
  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1)
  }
  const handleClick = () => {
    activeStep === announcements?.edges?.length - 1
      ? handleSubDialogClose()
      : handleNext()
  }
  const getStepContent = (activeStep: any) => {
    if (announcements?.edges?.[activeStep]?.node)
      return announcements?.edges?.[activeStep]?.node?.content
  }

  return (
    <>
      <Stepper
        activeStep={activeStep}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        {announcements?.edges.map((item: any, index: any) => (
          <Step key={index}>
            <StepLabel>{item?.node?.displayType}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div style={{ fontWeight: 600, fontSize: 'larger' }}>
          {announcements?.edges?.[activeStep]?.node?.title}
        </div>
        <p dangerouslySetInnerHTML={{ __html: getStepContent(activeStep) }} />
        <div style={{ textAlign: 'right' }}>
          {activeStep !== 0 && <Button onClick={handleBack}>Back</Button>}
          <Button variant="contained" color="primary" onClick={handleClick}>
            {activeStep === announcements?.edges.length - 1 ? 'Close' : 'Next'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default AnnouncementsBellDialog
