import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  IconButton,
  Badge,
  Menu,
  makeStyles,
  ListItem,
  ListItemText,
  MenuItem,
  Button,
} from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'
import { ErrorBoundary } from 'react-error-boundary'
import { RightSide_communication_data$data } from './__generated__/RightSide_communication_data.graphql'

type CommunicationMenuProps = {
  data: RightSide_communication_data$data | null
}
const CommunicationMenu: React.FC<CommunicationMenuProps> = ({ data }) => {
  const classes = useStyles()

  const { push } = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleSeeMore = () => {
    handleClose()
    push('/communication')
  }
  const handleSeeLink = (id: any) => {
    const claimId = btoa(`ClaimJob:${id}`)
    handleClose()
    push(`/claim/${claimId}/histories`)
  }

  return (
    <ErrorBoundary fallback={<>Communication Menu Error</>}>
      <IconButton
        className={anchorEl ? classes.iconOnOpen : classes.icon}
        onClick={handleClick}
        color="inherit"
      >
        <Badge
          max={99}
          color="error"
          badgeContent={data?.communications?.totalCount}
        >
          <MailIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        {/* <ListItem tabIndex={-1}>
          <ListItemText primary="Communication Tasks" />
        </ListItem> */}
        <div className={classes.Info}>Communication Tasks</div>
        {data?.communications?.edges?.map((edge, index) => {
          if (!edge?.node) return null
          return (
            <MessageItem
              key={index}
              {...edge.node}
              onClick={() => handleSeeLink(edge.node?.claimId)}
            />
          )
        })}
        <ListItem style={{ borderTop: '2px grey solid' }}>
          <ListItemText
            primary={`Total: ${data?.communications?.totalCount}`}
          />
          <Button
            variant="outlined"
            color="primary"
            className={classes.seeMoreButton}
            onClick={handleSeeMore}
          >
            See more...
          </Button>
        </ListItem>
      </Menu>
    </ErrorBoundary>
  )
}
export default CommunicationMenu

const useStyles = makeStyles(theme => ({
  menuPaper: {
    minWidth: 200,
    maxWidth: 350,
  },
  seeMoreButton: {
    marginLeft: theme.spacing(),
  },
  iconOnOpen: {
    background: 'rgba(0,0,0, 0.3)',
  },
  icon: {},
  Info: {
    padding: '15px',
    fontWeight: 600,
    borderBottom: '2px grey solid',
  },
  ListItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

type MessageItemProps = {
  claimId: string | null
  claimRef: string
  message: string | null
  onClick: () => void
}
const MessageItem: React.FC<MessageItemProps> = ({
  claimId,
  claimRef,
  message,
  onClick,
}) => {
  const classes = useStyles()
  return (
    <ListItem key={claimId} component={MenuItem} button onClick={onClick}>
      <ListItemText
        primary={claimRef}
        secondary={message}
        classes={{ secondary: classes?.ListItemText }}
      />
    </ListItem>
  )
}
