import React from 'react'

import { makeStyles, Button, Icon } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'

import { useFragment } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { LeftSide_data$key } from './__generated__/LeftSide_data.graphql'
import { LeftSide_menuData$key } from './__generated__/LeftSide_menuData.graphql'
import { COMPANY_RESOURCE } from 'configs'
import Hidden from '@material-ui/core/Hidden'

type LeftSideProps = {
  data: LeftSide_data$key | null
  menuData: LeftSide_menuData$key | null
}
const LeftSide: React.FC<LeftSideProps> = props => {
  const classes = useStyles()
  const data = useFragment(
    graphql`
      fragment LeftSide_data on UserProfile {
        company {
          customisation {
            companyLogo: displayCompanyLogo
          }
        }
        privileges {
          menus {
            nodeName
          }
        }
      }
    `,
    props.data
  )

  const leftSideMenuItem = useFragment<LeftSide_menuData$key>(
    graphql`
      fragment LeftSide_menuData on Query {
        currentUser {
          menuItems {
            admin {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            claim {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            config {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            dashboard {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            outstandingCommunication {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            reporting {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            scoreboard {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            video {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            bookingService {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
            specialist {
              alignment
              icon
              id
              isDisplay
              isExternal
              label
              url
            }
          }
        }
      }
    `,
    props.menuData
  )

  const routes = [
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.dashboard?.label,
      path: '/dashboard',
      icon: leftSideMenuItem?.currentUser?.menuItems?.dashboard?.icon,
      isDisplay: leftSideMenuItem?.currentUser?.menuItems?.dashboard?.isDisplay,
    },
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.claim?.label,
      path: '/claims',
      icon: leftSideMenuItem?.currentUser?.menuItems?.claim?.icon,
      isDisplay: leftSideMenuItem?.currentUser?.menuItems?.claim?.isDisplay,
    },
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.scoreboard?.label,
      path: '/scoreboard',
      icon: leftSideMenuItem?.currentUser?.menuItems?.scoreboard?.icon,
      isDisplay:
        leftSideMenuItem?.currentUser?.menuItems?.scoreboard?.isDisplay,
    },
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.video?.label,
      path: '/video/rooms',
      icon: leftSideMenuItem?.currentUser?.menuItems?.video?.icon,
      isDisplay: leftSideMenuItem?.currentUser?.menuItems?.video?.isDisplay,
      href: leftSideMenuItem?.currentUser?.menuItems?.video?.url,
    },
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.admin?.label,
      path: '/admin',
      icon: leftSideMenuItem?.currentUser?.menuItems?.admin?.icon,
      isDisplay: leftSideMenuItem?.currentUser?.menuItems?.admin?.isDisplay,
      href: leftSideMenuItem?.currentUser?.menuItems?.admin?.url,
    },
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.specialist?.label,
      path: '/specialist',
      icon: leftSideMenuItem?.currentUser?.menuItems?.specialist?.icon,
      isDisplay:
        leftSideMenuItem?.currentUser?.menuItems?.specialist?.isDisplay,
    },
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.reporting?.label,
      path: '/reports',
      icon: leftSideMenuItem?.currentUser?.menuItems?.reporting?.icon,
      isDisplay: leftSideMenuItem?.currentUser?.menuItems?.reporting?.isDisplay,
      href: leftSideMenuItem?.currentUser?.menuItems?.reporting?.url,
    },
    {
      label: leftSideMenuItem?.currentUser?.menuItems?.bookingService?.label,
      //'Booking Service'
      path: '/booking',
      icon: leftSideMenuItem?.currentUser?.menuItems?.bookingService?.icon,
      isDisplay:
        leftSideMenuItem?.currentUser?.menuItems?.bookingService?.isDisplay,
    },
  ]
  const location = useLocation()

  const companyLogo = data?.company?.customisation?.companyLogo
  // const allowedRoutes = data?.privileges?.menus?.map(menu => {
  //   return `/${menu?.nodeName.toLowerCase()}`
  // })
  // console.log(allowedRoutes)

  // const filteredRoutes = routes.filter(route => {
  //   return Boolean(allowedRoutes?.find(s => route.path.includes(s)))
  // })
  return (
    <>
      {companyLogo && (
        <Hidden only="sm">
          <img
            className={classes.companyLogo}
            src={COMPANY_RESOURCE + '/' + companyLogo}
            alt="ENData Claims"
          />
        </Hidden>
      )}
      {routes
        .filter(t => t.isDisplay === true)
        .map(route => (
          <Button
            key={route.path}
            startIcon={<Icon>{route.icon}</Icon>}
            color="inherit"
            size="large"
            className={`${classes.routeButton} ${
              route.path.includes(location.pathname)
                ? classes.routeButtonActive
                : ''
            }`}
            component={route.href ? 'a' : Link}
            to={route.path}
            href={route.href as any}
            target={route.href ? '_blank' : undefined}
          >
            {route.label}
          </Button>
        ))}
    </>
  )
}
export default LeftSide

const useStyles = makeStyles(() => ({
  companyLogo: {
    marginRight: 8,
    verticalAlign: 'middle',
    height: 'calc(50% - 4px)',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  routeButton: {
    height: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  routeButtonActive: {
    background: 'rgba(0,0,0, 0.3)',
  },
}))
