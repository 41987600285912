import React from 'react'

import { COMPANY_RESOURCE } from 'configs'
import { useFragment } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { RightSide_data$key } from './__generated__/RightSide_data.graphql'
import SystemMenu from './SystemMenu'
import CommunicationMenu from './CommunicationMenu'
import { RightSide_menuIcon_data$key } from './__generated__/RightSide_menuIcon_data.graphql'
import Hidden from '@material-ui/core/Hidden'
import { RightSide_announcementData$key } from './__generated__/RightSide_announcementData.graphql'
import { RightSide_communication_data$key } from './__generated__/RightSide_communication_data.graphql'
import AnnouncementsBell from './AnnouncementsBell'

export type RightSideProps = {
  data: RightSide_data$key | null
  menuData: RightSide_menuIcon_data$key | null
  announcementData: RightSide_announcementData$key
  communicationData: RightSide_communication_data$key | null
  disposeNavbarQuery: any
}
const RightSide: React.FC<RightSideProps> = props => {
  const data = useFragment(
    graphql`
      fragment RightSide_data on UserProfile {
        company {
          customisation {
            applicationLogo
          }
        }
        privileges {
          menus {
            nodeName
          }
        }
      }
    `,
    props.data
  )

  const announcementData = useFragment(
    graphql`
      fragment RightSide_announcementData on Query {
        ...AnnouncementsBell_data
        currentUser {
          menuItems {
            announcementsNotification {
              icon
              isDisplay
            }
          }
        }
      }
    `,
    props.announcementData
  )

  const menuIcon = useFragment<RightSide_menuIcon_data$key>(
    graphql`
      fragment RightSide_menuIcon_data on Query {
        ...SystemMenuPropsData
      }
    `,
    props.menuData
  )

  const communicationData = useFragment(
    graphql`
      fragment RightSide_communication_data on Query {
        communications: claimCommunications(
          first: 2
          where: { boxes: Inbox, acknowledged: false }
        ) {
          totalCount
          edges {
            node {
              claimId
              claimRef
              message
            }
          }
        }
      }
    `,
    props.communicationData
  )

  const communicationMenu = data?.privileges?.menus?.filter(menu => {
    return menu?.nodeName === 'communicationtasks'
  })
  const isCommunicationMenu = Boolean(communicationMenu?.length)
  return (
    <>
      {isCommunicationMenu && <CommunicationMenu data={communicationData} />}
      {announcementData?.currentUser?.menuItems?.announcementsNotification
        ?.isDisplay && (
        <AnnouncementsBell
          data={announcementData}
          icon={
            announcementData?.currentUser?.menuItems?.announcementsNotification
              ?.icon
          }
        />
      )}
      <div style={{ marginRight: 4, minWidth: 52 }}>
        <SystemMenu
          menuData={menuIcon}
          disposeNavbarQuery={props?.disposeNavbarQuery}
        />
      </div>
      <Hidden only={['sm', 'md']}>
        {data?.company?.customisation?.applicationLogo && (
          <img
            style={{ height: '100%' }}
            alt="Endata Application Logo"
            src={`${COMPANY_RESOURCE}/${data?.company?.customisation?.applicationLogo}`}
          />
        )}
        <img
          style={{ height: '100%' }}
          src={`${COMPANY_RESOURCE}/endata/logo.png`}
          alt="ENData Claims"
        />
      </Hidden>
    </>
  )
}
export default RightSide
