import createTheme from './theme'

export type ThemeName =
  | 'Endata'
  | 'Sophia'
  | 'Jade'
  | 'Amber'
  | 'Sakura'
  | 'Juicy'
  | 'Rock'
  | 'Harmony'
  | 'Easy'
  | 'Ins'
  | 'Aws'

export default async (name: ThemeName) => {
  const Themes = await import(`./Themes.json`).then(res => res.default)
  // fallback to use Sophia
  const theme = Themes[name] ? Themes[name] : Themes['Sophia']
  return createTheme(theme)
}
