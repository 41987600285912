/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AnnouncementTypeEnum = "System_Release" | "Terms_And_Conditions" | "%future added value";
export type Announcement_data = {
    readonly announcements: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly title: string;
                readonly content: string;
                readonly id: string;
                readonly type: AnnouncementTypeEnum | null;
                readonly displayType: string;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "Announcement_data";
};
export type Announcement_data$data = Announcement_data;
export type Announcement_data$key = {
    readonly " $data"?: Announcement_data$data;
    readonly " $fragmentRefs": FragmentRefs<"Announcement_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Announcement_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "disabled": false,
            "includeAcknowledged": false
          }
        }
      ],
      "concreteType": "AnnouncementConnection",
      "kind": "LinkedField",
      "name": "announcements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnnouncementEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Announcement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "content",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "announcements(where:{\"disabled\":false,\"includeAcknowledged\":false})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = 'c5c2b9dc0b35349f57fb4bd9bd614f9b';
export default node;
