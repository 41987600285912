import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useAppContext } from 'App'
import { useLocation, useHistory } from 'react-router-dom'
import { Icon, Card, Button } from '@material-ui/core'

type RouteErrorBoundaryProps = FallbackProps & {
  errorUrl?: string
}

const RouteErrorBoundary: React.FC<RouteErrorBoundaryProps> = ({
  error,
  componentStack,
  resetErrorBoundary,
  errorUrl,
}) => {
  const initialRoute = localStorage.getItem('ROOT_ROUTE')
  const { resetEnvironment } = useAppContext()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const environment = process.env.NODE_ENV
  const index = pathname.lastIndexOf('/')
  const pushString = pathname.substring(0, index)
  if (errorUrl && pathname !== errorUrl) {
    resetErrorBoundary()
  }

  const isToken =
    localStorage.getItem('ACCESS_TOKEN') &&
    localStorage.getItem('REFRESH_TOKEN')

  const handleTryAgain = () => {
    if (resetEnvironment) resetEnvironment()
    resetErrorBoundary()
    push(initialRoute ? `/${initialRoute}` : `${pushString}/job-info`)
  }

  const handleKickOut = React.useCallback(() => {
    localStorage.clear()
    if (resetEnvironment) resetEnvironment()
    resetErrorBoundary()
    //window.location.href = 'https://www.endataclaims.com/'
    if (process.env.REACT_APP_DEFAULT_REDIRECT_URL) {
      window.location.href = `${process.env.REACT_APP_DEFAULT_REDIRECT_URL}`
    } else {
      push('/user/login')
    }
  }, [push, resetErrorBoundary, resetEnvironment])

  React.useEffect(() => {
    if (!isToken) {
      handleKickOut()
    }
  }, [isToken, handleKickOut])

  if (!isToken) return null
  return (
    <div role="alert">
      {environment === 'production' && (
        <Card
          style={{
            width: '400px',
            margin: '50px auto 20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Icon style={{ fontSize: '5rem', margin: '10px auto 10px' }}>
            filter_vintage
          </Icon>
          <h1 style={{ margin: 'auto' }}>Error</h1>
          <p style={{ margin: 'auto', textAlign: 'center', width: '300px' }}>
            Either something unexpected happened or you typed a wrong URL
          </p>
          <Button
            onClick={handleTryAgain}
            variant="contained"
            color="primary"
            style={{ width: '100px', margin: '10px auto 10px' }}
          >
            Try again
          </Button>
        </Card>
      )}
      {environment === 'development' && (
        <div>
          <p>Something went wrong:</p>
          <pre>{error?.message}</pre>
          <pre>{componentStack}</pre>
          <button onClick={handleTryAgain}>Try again</button>
        </div>
      )}
    </div>
  )
}
export default RouteErrorBoundary
