import React from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AnnouncementsBellDialog from './AnnouncementsBellDialog'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

type AnnouncementsBellProps = {
  icon: string | null
  data: any
}
const AnnouncementsBell: React.FC<AnnouncementsBellProps> = ({
  icon,
  ...props
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [openSubDialog, setOpenSubDialog] = React.useState(false)
  const handleSubDialogOpen = (index: any) => {
    setActiveStep(index)
    setOpenSubDialog(true)
  }
  const handleSubDialogClose = () => {
    setOpenSubDialog(false)
  }

  const [activeStep, setActiveStep] = React.useState(0)

  const data = useFragment(
    graphql`
      fragment AnnouncementsBell_data on Query {
        announcementsBellData: announcements(
          where: { includeAcknowledged: true, disabled: false }
        ) {
          edges {
            node {
              title
              content
              id
              type
              displayType
            }
          }
        }
      }
    `,
    props.data
  )

  return (
    <>
      <IconButton
        className={anchorEl ? classes.iconOnOpen : classes.icon}
        onClick={handleClick}
        color="inherit"
      >
        <Icon>{icon}</Icon>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <div className={classes.welcomeInfo}>Announcements</div>
        {data?.announcementsBellData?.edges.length > 0 ? (
          data?.announcementsBellData?.edges.map((item: any, index: any) => (
            <MenuItem
              className={classes.menuItem}
              key={index}
              onClick={() => handleSubDialogOpen(index)}
            >
              {item?.node?.title}
            </MenuItem>
          ))
        ) : (
          <MenuItem className={classes.menuItem}>No Announcements</MenuItem>
        )}
      </Menu>
      <Dialog
        open={openSubDialog}
        onClose={handleSubDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent className={classes.dialogContent}>
          <AnnouncementsBellDialog
            announcements={data?.announcementsBellData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleSubDialogClose={handleSubDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AnnouncementsBell

const useStyles = makeStyles(theme => ({
  menu: {
    '& div[role="document"]': {
      top: '50px !important',
    },
  },
  dialogContent: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  menuItem: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  iconButton: {
    height: '100%',
  },
  icon: {},
  iconOnOpen: {
    background: 'rgba(0,0,0, 0.3)',
  },
  itemIcon: {},
  itemText: {
    color: theme.palette.text.hint,
  },
  welcomeInfo: {
    padding: '15px',
    fontWeight: 600,
    borderBottom: '2px grey solid',
  },
}))
