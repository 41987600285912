import React from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AnnouncementDialog from './AnnouncementDialog'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import CloseIcon from '@material-ui/icons/Close'

type AnnouncementProps = {
  data: any
  isFetched: boolean
}

const Announcement: React.FC<AnnouncementProps> = ({ isFetched, ...props }) => {
  const data = useFragment(
    graphql`
      fragment Announcement_data on Query {
        announcements(where: { includeAcknowledged: false, disabled: false }) {
          edges {
            node {
              title
              content
              id
              type
              displayType
            }
          }
        }
      }
    `,
    props.data
  )
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  React.useEffect(() => {
    if (isFetched) {
      handleClickOpen()
    }
  }, [isFetched])

  const handleClose = () => {
    setOpen(false)
  }
  if (data?.announcements?.edges?.length === 0) return null

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <AnnouncementDialog
          handleClose={handleClose}
          announcements={data?.announcements?.edges}
        />
      </DialogContent>
    </Dialog>
  )
}

export default Announcement
