import { createMuiTheme, ThemeOptions } from '@material-ui/core'
import Themes from './Themes.json'

const themeStore = localStorage.getItem('THEME_COLOR')
const defaultTheme = themeStore //@ts-ignore
  ? { theme: Themes[themeStore], name: themeStore }
  : process.env.NODE_ENV === 'development' //@ts-ignore
  ? { theme: Themes['dev'], name: 'dev' }
  : { theme: Themes['Sophia'] as any, name: 'Sophia' }
if (!themeStore) localStorage.setItem('THEME_COLOR', defaultTheme.name)

const defaultMuiTheme = createMuiTheme({ ...defaultTheme.theme })

export default (options: ThemeOptions = {}) => {
  return createMuiTheme({
    ...defaultTheme.theme,
    ...options,
    palette: {
      ...defaultTheme?.theme.palette,
      ...options.palette,
      background: {
        ...options.palette?.background,
        default: '#ececec',
      },
    },
    overrides: {
      ...options.overrides,
      MuiCssBaseline: {
        ...options.overrides?.MuiCssBaseline,
        '@global': {
          '*': {
            'scrollbar-width': 'thin',
          },
          '*::-webkit-scrollbar': { width: 10, height: 10 },
          '::-webkit-scrollbar-thumb': {
            background: defaultMuiTheme.palette.primary.main,
            borderRadius: 10,
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: defaultMuiTheme.palette.primary.dark,
          },
        },
      },
      MuiFormLabel: {
        root: {
          // color: 'rgba(0, 0, 0, 0.84) !important',
          fontWeight: '600 !important',
        },
      },
      MuiSwitch: {
        track: { backgroundColor: 'red' },
        switchBase: { color: 'red' },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
      },
      MuiTab: {
        root: {
          fontWeight: 700,
        },
        wrapper: {
          textTransform: 'none',
        },
      },
      MuiTabPanel: {
        width: '100%',
      },
    },
  })
}
