// import 'react-app-polyfill/ie9'
// import 'react-app-polyfill/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'serviceWorker'

import App from 'App'
ReactDOM.render(<App />, document.getElementById('root'))
serviceWorker.unregister()
