import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

type PrivateRouteProps = RouteProps & {
  component: any
  accessPath?: string
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  accessPath,
  ...rest
}) => {
  const token = localStorage.getItem('ACCESS_TOKEN')
  const accessPaths = localStorage.getItem('ACCESS_PATHS')

  return (
    <Route
      {...rest}
      render={props =>
        token && Component ? (
          !accessPath || accessPaths?.includes(accessPath) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/NotFound', state: { from: props.location } }}
            />
          )
        ) : (
          <Redirect
            to={{ pathname: '/user/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
export default PrivateRoute
